import React from "react";
import Seo from "../components/seo";
import useIsInViewport from "../hooks/useIsInViewport";
import { Link } from "gatsby";
// import { HashLink } from "react-router-hash-link";

/* START: images import */
import marticka from "../img/approach/marticka.jpg";
import standards from "../img/approach/standards.jpg";
import code from "../img/approach/code.jpg";
/* END: images import */

const Intro = ({ setObserverRef }) => (
    <div className="article" id="how" ref={setObserverRef} data-observer={0}>
        <h2 className="line sm animate-1">Skill-up or partner-up</h2>
        <div>
            <blockquote className="desktop noq animate-3">
                <p>We’re used to syncing with development sprint cycles and meeting client deadlines.</p>
            </blockquote>
        </div>
        <article>
            <p className="animate-2">
                Dedicated in-house teams are hard to beat, but when that’s not an option we’re happy to offer our services. We’ve spent years working remotely for design agencies,
                businesses, start-ups and other development agencies, and are proud to say we’ve made each and every one happy clients.{" "}
            </p>
            <h3 className="animate-4">We work how you work</h3>
            <p className="animate-5">
                Code 8 has evolved as a company to be adaptive and flexible so we can fit with your team. We want to help you make the right technical choices and can consult with
                you to arrive at the best solution. We also want to help support your team and fill your resource-gaps. We’re used to syncing with development sprint cycles and
                meeting deadlines.
            </p>
            <h3 className="animate-6">Communication</h3>
            <p className="animate-7">
                Remote working is easier today than ever before. The variety of communication tools available, as well as reliance on the traditional ones, means we can keep in
                constant communication with our clients. Our key team members all speak and write fluent English and the ones that aren’t fluent yet are working on it (we have our
                own weekly English tutor).
            </p>
        </article>
    </div>
);

const Extending = ({ setObserverRef }) => (
    <div ref={setObserverRef} data-observer={1}>
        <article className="article animate-1" id="extending">
            <h2 className="line animate-2">Extending your team</h2>
            <p className="animate-3">
                Experience tells us that our clients like to have a dedicated developer on a project. Someone who gets to know the project inside out, so we try to ensure you will
                have just that. We ensure project knowledge is shared with the broader team so that everyone is up-to-speed so if your dedicated team member is not available,
                someone knowledgeable can help.
            </p>
        </article>

        <article id="working" className="clearfix animate-4">
            <article className="article animate-5">
                <h3 className="animate-6">Working with developers</h3>
                <p className="animate-7">For tech teams that require front-end support we are used to fitting in with your teams sprint cycles. </p>
            </article>
            <article className="article animate-5">
                <h3 className="animate-6">Working with designers</h3>
                <p className="animate-7">We endeavour to make sure that the details in your designs make it through to the build.</p>
            </article>
        </article>
    </div>
);

const Planning = ({ setObserverRef }) => (
    <article className="article" id="planning" ref={setObserverRef} data-observer={2}>
        <h2 className="line sm animate-1">Fail to plan, plan to&nbsp;fail!</h2>
        <img className="animate-5" src={marticka} alt="Marticka.cz" />
        <p className="animate-2">
            We like to think ahead. We’d rather get things right from the start and in the past have had to help clients untangle coding hiccups they’ve had built elsewhere. So we
            need to work with you to properly scope your project. We offer a broad range of services but we’re happy to deliver just the part of a project that you need. This might
            be purely the HTML front-end or a full CMS build; we can cut the cloth accordingly.
        </p>
        <h3 className="animate-3">Budgeting</h3>
        <p className="animate-4">
            We can work to pre-defined budgets or for ongoing clients we keep track of time using our own time-tracking tool we built called{" "}
            <a href="https://www.marticka.cz/" target="_blank" rel="noopener noreferrer">
                Marticka
            </a>
            . At the end of each month we can give you a full breakdown of the hours spent across each project.
        </p>
    </article>
);

const Standards = ({ setObserverRef }) => (
    <article className="article" id="standards" ref={setObserverRef} data-observer={3}>
        <h2 className="line sm animate-1">The latest web standards</h2>
        <img className="animate-2" src={standards} alt="standards" />
        <p className="animate-3">
            There is no doubt that the Web has made some great progress in the last decade and with accelerating browser release cycles there is more goodness to come. At Code 8 we
            are watching the latest tech advances closely but we as well understand the arguments for backward compatibility. The stand we take on these two extremities depends
            greatly on client, his target audience and level of dedication he wishes to exercise. We've been using testing frameworks like{" "}
            <a href="https://jestjs.io/" target="_blank" rel="noopener noreferrer">
                Jest
            </a>
            ,{" "}
            <a href="https://mochajs.org/" target="_blank" rel="noopener noreferrer">
                Mocha
            </a>{" "}
            or{" "}
            <a href="https://jasmine.github.io/" target="_blank" rel="noopener noreferrer">
                Jasmine
            </a>
            {/*             ,{" "}
<a
href="https://www.seleniumhq.org/projects/webdriver/"
target="_blank"
rel="noopener noreferrer"
>
Selenium,
</a> */}
            {/*             ,{" "}
<a
href="http://angular.github.io/protractor"
target="_blank"
rel="noopener noreferrer"
>
Angular Protractor
</a> */}
            {/*             ) or JavaScript code quality tools (
<a
href="http://jslint.com"
target="_blank"
rel="noopener noreferrer"
>
JSLint
</a> */}{" "}
            to mitigate bugs in JavaScript for example. Whatever your requirement we are here to help finding the right setup for each case.
        </p>
    </article>
);

const Code = ({ setObserverRef }) => (
    <article className="article" id="code" ref={setObserverRef} data-observer={4}>
        <h2 className="line sm animate-1">It’s your code</h2>
        <img className="animate-5" src={code} alt="Code" />
        <p className="animate-2">
            We want you to own your code, we use a Git or Subversion repository to store releases. We believe it’s important for clients to be able to take their code where they
            like and work with whomever they like. Not all development agencies think this way.
        </p>
        <h3 className="animate-3">The right tool for the&nbsp;job</h3>
        <p className="animate-4">
            We are experts at what we do, but we don’t profess to be capable of anything using our in-house team, and so we have a network of specialists to help bolster our
            skill-sets if an when required.
        </p>
    </article>
);

const Experience = ({ setObserverRef }) => (
    <section ref={setObserverRef} data-observer={5}>
        <article className="article" id="experience">
            <h2 className="line animate-1">Our services</h2>
            <div className="text">
                <p className="animate-2">
                    We offer <a href="/services/#SPAs">React and Angular JS</a> dvelopment, <a href="/services/#mobileApps">mobile apps</a> and custom{" "}
                    <a href="/services#bespoke">back-end solutions</a>, all using a set of established and versatile <a href="/services#experience">stacks</a>.
                </p>
                <p className="animate-3">
                    <Link to="/services">Our services</Link>
                </p>
            </div>
        </article>
    </section>
);

const Approach = () => {
    const { setRef } = useIsInViewport();
    return (
        <div>
            <Seo title="Approach" />
            <Intro setObserverRef={setRef} />
            <Extending setObserverRef={setRef} />
            <section className="halfContentSection">
                <Planning setObserverRef={setRef} />
                <Standards setObserverRef={setRef} />
                <Code setObserverRef={setRef} />
            </section>
            <Experience setObserverRef={setRef} />
        </div>
    );
};
export default Approach;
